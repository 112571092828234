export const TitleWeb = "MC Insider";
export const AuthProvider = "mcinsider";
export const DefaultSponsor = "mcinsider";
export const DomainName = "https://mcinsider.ltd/";
export const DomainRoot = "https://mcinsider.ltd/";
export const NameInc = "MC Insider Inc.";
export const EmailApp = "admin@mcinsider.ltd";
export const ContactAdmin = "+60 8-8787-5353";

// COLOR PALETTE
export const PrimaryColor = "#2962ff";
export const PrimaryDark = "#2E0101";
export const SecondaryColor = "#FFC300";
export const SecondaryDark = "#3D2F01";
export const TextColor = "#535353";
